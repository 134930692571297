import { useContext } from 'react';

import { fg } from '@confluence/feature-gating';
import { expValEquals } from '@confluence/feature-experiments';

import { SessionDataContext } from './SessionDataContext';

type ExperimentCohorts = 'control' | 'test';

const getRovoEntitlementValues = ({
	isRovoEnabledInTCS,
	isRovoLLMEnabledInTCS,
}: {
	isRovoEnabledInTCS: boolean;
	isRovoLLMEnabledInTCS: boolean;
}) => {
	// If a user is not entitled to Rovo- DO NOTHING and return the original Rovo entitlement values.
	// The goal of the experiment is NEVER to enable Rovo for ineligible users.
	if (!isRovoEnabledInTCS) {
		return { isRovoEnabled: isRovoEnabledInTCS, isRovoLLMEnabled: isRovoLLMEnabledInTCS };
	}

	// A kill-switch to disable the experiment and skip ANY changes to the Rovo entitlement values.
	if (!fg('rovo_team25_rollout_performance_exp_enabled')) {
		return { isRovoEnabled: isRovoEnabledInTCS, isRovoLLMEnabled: isRovoLLMEnabledInTCS };
	}

	// If a user is in the Control cohort we're overriding the entitlement check with false to NOT serve Rovo experience.
	// Test cohort: users who get the new Rovo experience -> do NOT override the TCS entitlement.
	// Control cohort: users who get the legacy experience (the baseline) -> override the TCS entitlement with false.
	if (expValEquals<ExperimentCohorts>('rovo_team25_rollout_performance_exp', 'cohort', 'control')) {
		return { isRovoEnabled: false, isRovoLLMEnabled: false };
	}

	// Return the original values for the Test group.
	return { isRovoEnabled: isRovoEnabledInTCS, isRovoLLMEnabled: isRovoLLMEnabledInTCS };
};

/**
 * This a temporary hook to support the Rovo performance experiment.
 * It works the same way as `useSessionData`, but overrides isRovoEnabled and isRovoLLMEnabled based on the experiment.
 *
 * https://console.statsig.com/LqivKg6ADZZaGczRfBKfX/experiments/rovo_team25_rollout_performance_exp
 */
export const useSessionDataWithRovoExperiment = () => {
	const sessionData = useContext(SessionDataContext);
	const { isRovoEnabled, isRovoLLMEnabled } = sessionData;
	const overriddenRovoEntitlementValues = getRovoEntitlementValues({
		isRovoEnabledInTCS: isRovoEnabled,
		isRovoLLMEnabledInTCS: isRovoLLMEnabled,
	});

	return {
		...sessionData,
		...overriddenRovoEntitlementValues,
	};
};
