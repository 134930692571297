import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { isFedRamp } from '@atlassian/atl-context';

import { usePageContentId } from '@confluence/page-context';
import { useSessionDataWithRovoExperiment } from '@confluence/session-data';
import { markErrorAsHandled } from '@confluence/graphql';
import { fg } from '@confluence/feature-gating';

interface OperationCheckResult {
	operation: string;
	__typename: 'OperationCheckResult';
}

type UseIsAIEnabledForContentProps = {
	contentId?: string;
};

const useIsAIEnabledForContentQuery = gql`
	query useIsAIEnabledForContentQuery($contentId: ID!) {
		content(id: $contentId) {
			nodes {
				id
				operations {
					targetType
					operation
				}
			}
		}
	}
`;

const isAIReadAllowed = (operationsList: OperationCheckResult[]): boolean => {
	if (operationsList) {
		return operationsList.some((item) => item.operation === 'ai_read');
	} else {
		return false;
	}
};

/**
 * A hook created as part of the AI Data Security Policy integration to evaluate content-based AI-enablement.
 *
 * Returns an object containing `isAIEnabledForContent` and `isRovoEnabledForContent`.
 *
 * If the `confluence_ai_access_policy` FG is OFF, values from `useSessionData` are returned directly:
 * `isAdminHubAIEnabled` (whether or not AI is enabled for the tenant) and `isRovoEnabled` (whether or not
 * Rovo is enabled for the tenant) respectively.
 *
 * If the FG is ON, a GraphQL call is made to ensure that an AI block policy has not been placed on the
 * specified contentId, in addition to checking the values of `isAdminHubAIEnabled` and `isRovoEnabled`.
 *
 * @param {Object} params - The hook's input object
 * @param {string} params.contentId The contentId of the piece of content to determine AI enablement of
 */
export const useIsAIEnabledForContent = ({
	contentId,
}: UseIsAIEnabledForContentProps): {
	isAIEnabledForContent: boolean;
	isRovoEnabledForContent: boolean;
} => {
	const { isAdminHubAIEnabled, isRovoEnabled, isRovoLLMEnabled } =
		useSessionDataWithRovoExperiment();
	const shouldShowRovo = fg('rovo_use_rovo_llm_enabled_check') ? isRovoLLMEnabled : isRovoEnabled;

	const { data, error } = useQuery(useIsAIEnabledForContentQuery, {
		variables: {
			contentId,
		},
		skip:
			!contentId ||
			isFedRamp() ||
			(!isAdminHubAIEnabled && !shouldShowRovo) ||
			!fg('confluence_ai_access_policy'),
	});

	if (error) {
		markErrorAsHandled(error);
	}

	if (!fg('confluence_ai_access_policy'))
		return {
			isAIEnabledForContent: isAdminHubAIEnabled,
			isRovoEnabledForContent: shouldShowRovo,
		};

	const aiRead = isAIReadAllowed(data?.content?.nodes[0]?.operations);

	return {
		isAIEnabledForContent: aiRead && isAdminHubAIEnabled,
		isRovoEnabledForContent: aiRead && shouldShowRovo,
	};
};

/**
 * Higher-order component that adds the ability to check for content-based AI enablement by leveraging
 * the `useIsAIEnabledForContent` hook.
 *
 * @template P - The props type of the wrapped component
 * @param {React.ComponentType<P>} WrappedComponent - The component to wrap
 * @returns {React.ComponentType<P & {
 *   isAIEnabledForContent: boolean,
 *   isRovoEnabledForContent: boolean
 * }>} Component with AI enablement status passed down as props
 */
export function withIsAIEnabledForContent(WrappedComponent: React.ComponentType<any>) {
	return function EnhancedComponent(props: any) {
		const [contentId] = usePageContentId();
		const { isAIEnabledForContent, isRovoEnabledForContent } = useIsAIEnabledForContent({
			contentId: contentId ?? '',
		});

		return (
			<WrappedComponent
				{...props}
				isAIEnabledForContent={isAIEnabledForContent}
				isRovoEnabledForContent={isRovoEnabledForContent}
			/>
		);
	};
}
